<template>
    <div class="app-container">
        <el-form :model="form" :rules="formRules" ref="form" label-position="top">
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="page-title">{{ $t('sales.billing.title') }}</div>
                </el-col>

                <el-col :span="12" class="text-right">
                    <el-button type="primary" size="mini" @click="submit">{{ $t('sales.billing.save') }}</el-button>
                </el-col>

                <el-col :span="24">
                    <Smartbill />
                </el-col>

                <el-col :span="24">
                    <FGO />
                </el-col>

                <el-col :span="24">
                    <Oblio />
                </el-col>

                <el-col :span="24">
                    <Facturis />
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import { get, call } from 'vuex-pathify';

export default {
    name: 'Facturare',
    data() {
        return {
            formRules: {
                smartbill: {
                    email: [{ required: true, message: this.$t('sales.billing.error.email-required'), trigger: ['change', 'blur'] }],
                    token: [{ required: true, message: this.$t('sales.billing.error.token-required'), trigger: ['change', 'blur'] }],
                    product_reference: [{ required: true, message: this.$t('sales.billing.error.product_reference-required'), trigger: ['change', 'blur'] }],
                    invoice_serie: [{ required: true, message: this.$t('sales.billing.error.invoice_serie-required'), trigger: ['change', 'blur'] }],
                    proforma_serie: [{ required: true, message: this.$t('sales.billing.error.proforma_serie-required'), trigger: ['change', 'blur'] }],
                    shipping_type: [{ required: true, message: this.$t('sales.billing.error.shipping_type-required'), trigger: ['change', 'blur'] }]
                },
                fgo: {
                    token: [{ required: true, message: this.$t('sales.billing.error.token-required'), trigger: ['change', 'blur'] }],
                    product_reference: [{ required: true, message: this.$t('sales.billing.error.product_reference-required'), trigger: ['change', 'blur'] }],
                    invoice_serie: [{ required: true, message: this.$t('sales.billing.error.invoice_serie-required'), trigger: ['change', 'blur'] }],
                    proforma_serie: [{ required: true, message: this.$t('sales.billing.error.proforma_serie-required'), trigger: ['change', 'blur'] }]
                },
                oblio: {
                    email: [{ required: true, message: this.$t('sales.billing.error.email-required'), trigger: ['change', 'blur'] }],
                    token: [{ required: true, message: this.$t('sales.billing.error.token-required'), trigger: ['change', 'blur'] }],
                    product_reference: [{ required: true, message: this.$t('sales.billing.error.product_reference-required'), trigger: ['change', 'blur'] }],
                    invoice_serie: [{ required: true, message: this.$t('sales.billing.error.invoice_serie-required'), trigger: ['change', 'blur'] }],
                    proforma_serie: [{ required: true, message: this.$t('sales.billing.error.proforma_serie-required'), trigger: ['change', 'blur'] }],
                    shipping_type: [{ required: true, message: this.$t('sales.billing.error.shipping_type-required'), trigger: ['change', 'blur'] }],
                    warehouse_unit: [{ required: true, message: this.$t('sales.billing.error.product_type-required'), trigger: ['change', 'blur'] }]
                },
                facturis: {
                    token: [{ required: true, message: this.$t('sales.billing.error.token-required'), trigger: ['change', 'blur'] }],
                    user: [{ required: true, message: this.$t('sales.billing.error.user-required'), trigger: ['change', 'blur'] }],
                    password: [{ required: true, message: this.$t('sales.billing.error.password-required'), trigger: ['change', 'blur'] }],
                    product_reference: [{ required: true, message: this.$t('sales.billing.error.product_reference-required'), trigger: ['change', 'blur'] }],
                    invoice_serie: [{ required: true, message: this.$t('sales.billing.error.invoice_serie-required'), trigger: ['change', 'blur'] }],
                    proforma_serie: [{ required: true, message: this.$t('sales.billing.error.proforma_serie-required'), trigger: ['change', 'blur'] }],
                    shipping_type: [{ required: true, message: this.$t('sales.billing.error.shipping_type-required'), trigger: ['change', 'blur'] }]
                }
            }
        };
    },
    components: {
        Smartbill: () => import('./facturare/Smartbill.vue'),
        FGO: () => import('./facturare/Fgo.vue'),
        Oblio: () => import('./facturare/Oblio.vue'),
        Facturis: () => import('./facturare/Facturis.vue')
    },
    computed: {
        form: get('account@billing')
    },
    methods: {
        GetBillingData: call('account/GetBillingData'),
        SaveBillingData: call('account/SaveBillingData'),
        submit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.SaveBillingData().then((response) => {
                        if (response.message.success) {
                            this.sbMsg({
                                type: 'success',
                                message: 'Datele au fost actualizate'
                            });

                            this.GetBillingData();
                        }
                    });
                } else {
                    this.sbMsg({
                        type: 'warn',
                        message: 'Verificati erorile'
                    });
                }
            });
        }
    },
    mounted() {
        this.GetBillingData();
    }
};
</script>

<style lang="scss" scoped>
.text-right {
    text-align: right;
}

.page-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
}
</style>
